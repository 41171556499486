import React from 'react';
import '../styles/button.scss';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { Link } from 'gatsby';

gsap.registerPlugin(ScrollToPlugin);

const Button = (props) => {
  const handleMenuItem = (section) => {
    gsap.to(window, {
      duration: 0.5,
      scrollTo: section,
      offsetY: 50,
    });
  };

  const renderedButton = () => {
    if (props.scroll) {
      return (
        <Link
          className='button'
          onClick={() => handleMenuItem(props.scroll)}
          to='/'
        >
          {props.children}
        </Link>
      );
    }
    if (props.link) {
      return (
        <a
          className='button'
          rel='noreferrer'
          target='_blank'
          href={props.link}
        >
          {props.children}
        </a>
      );
    }
    if (props.hoverText) {
      return (
        <button className='button hover'>
          <span className='first'>{props.children}</span>
          <span className='second'>{props.hoverText}</span>
        </button>
      );
    }
    return <button className='button'>{props.children}</button>;
  };

  return <>{renderedButton()}</>;
};

export default Button;
