import React, { useState } from 'react';
import Navigation from '../components/Navigation';
import '../styles/main.scss';
import '../styles/navigation.scss';
import Modal from '../sections/Modal';
export const WindowSizeContext = React.createContext();

const MainLayout = (props) => {
  const [modalOpen, setModalOpen] = useState(false);

  return <div>{props.children}</div>;
};

export default MainLayout;
