import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import '../styles/navigation.scss';
import useScrollListener from '../helpers/useScrollListener';
import useWindowSizeListener from '../helpers/useWindowSizeListener';
import logo from '../images/logon.svg';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);
const Navigation = (props) => {
  const [navClassList, setNavClassList] = useState(['navigation']);
  const [mobileMenu, setMobileMenu] = useState({
    active: false,
    open: false,
  });
  const [subMenu, setSubmenu] = useState(false);
  const scroll = useScrollListener();
  const windowSize = useWindowSizeListener();
  useEffect(() => {
    const classList = ['navigation box-shadow'];
    if (scroll.y - scroll.lastY > 0) classList.push('hide');
    if (scroll.y > 150 || subMenu) classList.push('scroll');
    if (windowSize <= 768) classList.push('overflowMenu');
    if (mobileMenu.active) classList.push('showMobile');
    setNavClassList(classList);
  }, [scroll.y, scroll.lastY, windowSize, mobileMenu.active, subMenu]);
  const toggleMobileMenu = () => {
    setMobileMenu((prev) => {
      return {
        ...prev,
        active: !prev.active,
      };
    });
  };
  const handleMenuItem = (section) => {
    toggleMobileMenu();
    gsap.to(window, {
      duration: 0.5,
      scrollTo: section,
      offsetY: 50,
    });
  };
  const handleMouseEnter = () => {
    setSubmenu(true);
  };
  const handleMouseLeave = () => {
    setSubmenu(false);
  };
  return (
    <>
      <div onClick={toggleMobileMenu} className='menu-link-wrapper'>
        <div
          className={
            mobileMenu.active ? 'menu-link menu-trigger-open' : 'menu-link'
          }
        >
          <span className='lines'></span>
        </div>
      </div>
      <div className={navClassList.join(' ')}>
        <div className='container'>
          <Link to='/'>
            <img className='logo' src={logo} alt='logo' />
          </Link>
          <ul>
            <li>
              <a href='#' onClick={() => handleMenuItem('#pzu')}>
                WIZYTA Z PZU
              </a>
              {/* <Link title='oferta' to='/'>
                WIZYTA Z PZU
              </Link> */}
            </li>
            <li
              onMouseEnter={() => handleMouseEnter()}
              onMouseLeave={() => handleMouseLeave()}
            >
              <Link title='oferta' to='/'>
                Oferta
              </Link>
              <ul className='submenu'>
                <li>
                  <a href='#' onClick={() => handleMenuItem('#leczenie')}>
                    strefa leczenia
                  </a>
                </li>
                <li>
                  <a href='#' onClick={() => handleMenuItem('#relax')}>
                    {' '}
                    strefa relaksu
                  </a>
                </li>
                <li>
                  <a href='#' onClick={() => handleMenuItem('#worksite')}>
                    strefa worksite
                  </a>
                </li>
              </ul>
            </li>
            <li
              onMouseEnter={() => handleMouseEnter()}
              onMouseLeave={() => handleMouseLeave()}
            >
              <Link title='o-nas' to='/'>
                O nas
              </Link>
              <ul className='submenu'>
                <li>
                  <a href='#' onClick={() => handleMenuItem('#pzu')}>
                    WIZYTA Z PZU
                  </a>
                </li>
                <li>
                  <a href='#' onClick={() => handleMenuItem('#mzareba')}>
                    Marcin Zaręba
                  </a>
                </li>
                <li>
                  <a href='#' onClick={() => handleMenuItem('#szareba')}>
                    Sara Zaręba
                  </a>
                </li>
                <li>
                  <a href='#' onClick={() => handleMenuItem('#sdlugolecka')}>
                    Sara Długołęcka
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <Link
                onClick={() => handleMenuItem('#kontakt')}
                title='kontakt'
                to='/'
              >
                Kontakt
              </Link>
            </li>
            <li
              onMouseEnter={() => handleMouseEnter()}
              onMouseLeave={() => handleMouseLeave()}
            >
              <Link title='umow-wizyte' to='/'>
                Umów wizytę
              </Link>
              <ul className='submenu'>
                <li>
                  <a
                    target='_blank'
                    onClick={() => setSubmenu(false)}
                    href='https://znajdzfizjoterapeute.pl/fizjoterapeuta/marcin-zareba/kujawsko-pomorskie/torun/fizjoergo-marcin-zareba/20170'
                  >
                    Marcin Zaręba
                  </a>
                </li>
                <li>
                  <a
                    target='_blank'
                    onClick={() => handleMouseLeave()}
                    href='https://znajdzfizjoterapeute.pl/fizjoterapeuta/sara-zareba/kujawsko-pomorskie/torun/fizjoergo-marcin-zareba/20605'
                  >
                    Sara Zaręba
                  </a>
                </li>
                <li>
                  <a href='#' onClick={() => handleMenuItem('#pzu')}>
                    WIZYTA Z PZU
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Navigation;
