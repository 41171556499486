import React from 'react';
import '../styles/modal.scss';
import Image from 'gatsby-image';
import {useStaticQuery, graphql} from 'gatsby';
import Button from '../components/Button';
import close from '../images/close.svg';

const Modal = (props) => {
  const data = useStaticQuery(graphql`
    query ModalQuery {
      mZareba: file(name: { eq: "marcin" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sZareba: file(name: { eq: "sara_zareba" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <div className='modal-container'>
      <div className='modal-content'>
        <img
          width
          height
          onClick={props.modalClose}
          src={close}
          alt=''
          className='close'
        />
        <p className='title'>umów wizytę u</p>
        <div className='offers'>
          <div className='single-offer'>
            <div className='img-container'>
              <Image fluid={data.mZareba.childImageSharp.fluid}/>
            </div>
            <span className='name'>Marcina Zaręby</span>
            <div className='button-container'>
              <Button
                link='https://znajdzfizjoterapeute.pl/fizjoterapeuta/marcin-zareba/kujawsko-pomorskie/torun/fizjoergo-marcin-zareba/20170'>
                Umów wizytę
              </Button>
            </div>
          </div>
          <div className='single-offer'>
            <div className='img-container'>
              <Image fluid={data.sZareba.childImageSharp.fluid}/>
            </div>
            <span className='name'>Sary Zaręby</span>
            <div className='button-container'>
              <Button
                link='https://znajdzfizjoterapeute.pl/fizjoterapeuta/sara-zareba/kujawsko-pomorskie/torun/fizjoergo-marcin-zareba/20605'>
                Umów wizytę
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
